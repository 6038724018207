<template>
  <div>
    <app-navbar />
    <div class="auth-wrapper auth-v1 px-2">
      <div class="py-2">
        <!-- Login v1 -->
        <b-card class="mb-0 login-v1">
          <b-card-text class="mb-2 text-left">
            Welcome to EasyRentals Backoffice™.
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >

              <!-- email -->
              <b-form-group
                label-class="inputLabel"
                label-for="username"
                label="Username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Username"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="userName"
                    class="boxShadow"
                    name="login-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Type..."
                    autofocus
                    :tabindex="tabIndex.tabIndex1"
                    @keydown.tab.prevent="focusNextInput"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group>
                <label
                  class="inputLabel"
                  for="password"
                >Password</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge boxShadow"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="login-password"
                      placeholder="Type..."
                      :tabindex="tabIndex.tabIndex2"
                      @keydown.tab.prevent="focusNextInput"
                    />

                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

                <b-link
                  :to="{name:'auth-forgot-password'}"
                  class="mt-2 forgot-password-link"
                  :tabindex="tabIndex.tabIndex3"
                >
                  <small class="subtitle text-decoration-none">Forgot Password?</small>
                </b-link>
                <!--                <b-button-->
                <!--                  variant="link"-->
                <!--                  class="p-0"-->
                <!--                  @click="keyCloak()"-->
                <!--                >-->
                <!--                  <small class="subtitle text-decoration-none">KeyCloak</small>-->
                <!--                </b-button>-->
              </b-form-group>

              <!-- submit button -->
              <b-overlay
                :show="isLoading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="success"
                class="login-button  d-inline-block"
              >
                <b-button
                  variant="success"
                  type="submit"
                  :disabled="invalid || isLoading"
                  class="font-weight-bolder"
                  :tabindex="tabIndex.tabIndex4"
                  @keydown.tab.prevent="resetFocus"
                >
                  Log In
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>

        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardText, BInputGroup, BInputGroupAppend, BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import AppNavbar from '@/@core/layouts/components/app-navbar/AppNavbar.vue'
import { scrollToError } from '@core/utils/utils'

export default {
  components: {
    BOverlay,
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    AppNavbar,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      userName: '',
      password: '',
      status: '',
      // validation rules
      required,
      tabIndex: {
        tabIndex1: 1,
        tabIndex2: 2,
        tabIndex3: 3,
        tabIndex4: 4,
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.isLoading = true
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          useJwt.login({
            username: this.userName,
            password: this.password,
          })
            .then(response => {
              useJwt.afterLogin(this, response)
            })
            .catch(error => {
              this.errorNotification(this, error)
            }).finally(() => {
              this.isLoading = false
            })
        } else {
          scrollToError(this, this.$refs.loginForm)
        }
      })
      this.isLoading = false
    },
    keyCloak() {
      useJwt.redirectKeyCloakAuthUrl().catch(err => {
        this.errorNotification(this, err)
      })
    },
    focusNextInput(event) {
      const currentTabIndex = parseInt(event.target.getAttribute('tabindex'))
      const nextInputTabIndex = currentTabIndex + 1

      const nextInput = document.querySelector(`[tabindex="${nextInputTabIndex}"]`)
      if (nextInput) {
        nextInput.focus()
      }
    },
    resetFocus() {
      const firstInput = document.querySelector('[tabindex="1"]')
      if (firstInput) {
        firstInput.focus()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';

</style>
